/* global MatgenGlobal, API_ENV, TENANT_ENV */

const api_env = API_ENV === 'production' ? 'prod' : 'dev';
const tenant_env = TENANT_ENV ? TENANT_ENV : 'm4c';

const amplify_config = AMPLIFY_VARS => {
  MatgenGlobal.AMPLIFY_VARS = AMPLIFY_VARS;
  return {
    Auth: {
      mandatorySignIn: true,
      region: AMPLIFY_VARS.region,
      userPoolId: AMPLIFY_VARS.userPoolId,
      identityPoolId: AMPLIFY_VARS.identityPoolId,
      userPoolWebClientId: AMPLIFY_VARS.userPoolWebClientId,

      oauth: {
        domain: AMPLIFY_VARS.domain,
        scope: [
          'phone',
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: AMPLIFY_VARS.redirectSignIn,
        redirectSignOut: AMPLIFY_VARS.redirectSignOut,
        clientId: AMPLIFY_VARS.clientId,
        responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },

    Storage: {
      region: AMPLIFY_VARS.region,
      bucket: AMPLIFY_VARS.s3Bucket,
      userPoolId: AMPLIFY_VARS.userPoolId,
      identityPoolId: AMPLIFY_VARS.identityPoolId,
    },

    API: {
      endpoints: [
        {
          name: 'devauth',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/devauth`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'authenticated',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/authenticated`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'public',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/public`,
          region: AMPLIFY_VARS.region,
        },
        {
          name: 'mailer',
          endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}`,
          region: AMPLIFY_VARS.region,
        },
      ],
    },
  };
};

const config = {
  tenant: 'm4c',
  RedirectURL: 'https://localhost:8085',
  v2020: {
    dev: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_oVdL67JaF',
      identityPoolId: 'us-east-1:fb4c7c4c-2b4c-41fa-a9b9-7043714e035e',
      userPoolWebClientId: '3vmbg2tnn5mcp8kunnjo7ch278',
      domain: 'm4c-new.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.nia.nih.gov/',
      redirectSignOut: 'https://outreachpro.nia.nih.gov/',
      apiId: '2i9mwjmz45',
      apiStage: 'prod',
      s3Bucket: 'm4c-matgen',
    },
    prod: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_oVdL67JaF',
      identityPoolId: 'us-east-1:fb4c7c4c-2b4c-41fa-a9b9-7043714e035e',
      userPoolWebClientId: '3vmbg2tnn5mcp8kunnjo7ch278',
      domain: 'm4c-new.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.nia.nih.gov/',
      redirectSignOut: 'https://outreachpro.nia.nih.gov/',
      apiId: '2i9mwjmz45',
      apiStage: 'prod',
      s3Bucket: 'm4c-matgen',
    },
  },
  m4c: {
    dev: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_oVdL67JaF',
      identityPoolId: 'us-east-1:fb4c7c4c-2b4c-41fa-a9b9-7043714e035e',
      userPoolWebClientId: '3vmbg2tnn5mcp8kunnjo7ch278',
      domain: 'm4c.auth.us-east-1.amazoncognito.com',
      //redirectSignIn: 'https://outreachpro.one/',
      //redirectSignOut: 'https://outreachpro.one/',
      clientId: '1g0nnr4h99a3sd0vfs9',
      apiId: '2i9mwjmz45',
      apiStage: 'test',
      s3Bucket: 'm4c-matgen-dev',
    },
    prod: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_oVdL67JaF',
      identityPoolId: 'us-east-1:fb4c7c4c-2b4c-41fa-a9b9-7043714e035e',
      userPoolWebClientId: '3vmbg2tnn5mcp8kunnjo7ch278',
      domain: 'm4c.auth.us-east-1.amazoncognito.com',
      //redirectSignIn: 'https://outreachpro.one/',
      //redirectSignOut: 'https://outreachpro.one/',
      clientId: '1g0nnr4h99a3sd0vfs9',
      apiId: '2i9mwjmz45',
      apiStage: 'prod',
      s3Bucket: 'm4c-matgen',
    },
  },
  nia: {
    dev: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_Lzsiws867',
      identityPoolId: 'us-east-1:8bf749c0-45a0-4038-a972-9ae96d040b98',
      userPoolWebClientId: '5p60orar42r6263ujbgm8oogt',
      domain: 'm4c-new.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.nia.nih.gov/',
      redirectSignOut: 'https://outreachpro.nia.nih.gov/',
      //clientId: '1g0nnr4h99a3sd0vfs9', //Google federated Oauth clientid
      apiId: 'w7wd9iv6l3',
      apiStage: 'test',
      s3Bucket: 'm4c-matgen-dev-new',
    },
    prod: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_VWUjHKzRg',
      identityPoolId: 'us-east-1:c958f2b9-c19f-4c4f-9ecc-1a4f18b61376',
      userPoolWebClientId: '40ars5orefombarsnch1kmr770',
      domain: 'm4c-prod.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://outreachpro.nia.nih.gov/',
      redirectSignOut: 'https://outreachpro.nia.nih.gov/',
      //clientId: '1g0nnr4h99a3sd0vfs9', //Google federated Oauth clientid
      apiId: 'x14hijbira',
      apiStage: 'test',
      s3Bucket: 'm4c-matgen-new',
    },
  },
};

MatgenGlobal.base_config = config;

const MatgenConfig = amplify_config({
  ...config[tenant_env][api_env],
});

export default MatgenConfig;
