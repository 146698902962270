/* global $, MatgenGlobal, API_ENV, TENANT_ENV */

import '../../sass/style.scss';
import { v4 as UUID } from 'uuid';
import { MatgenEditor } from '../core/matgen-editor.js';
import { Amplify, Auth, Storage, API } from 'aws-amplify';
import MatgenConfig from './matgen-config.js';

//import { init as eventsInit } from './implementation/events/canvas-events.js';
//import { loadMenus } from './implementation/components/intra-menu.js';
import MatgenUIFunctions from '../implementation/ui/ui-functions.js';

let tfile, pfile, ttfile;

$(document).on('click', () => {
  if ($('#context-menu').length > 0) {
    $('#context-menu').remove();
  }
});

$(document).on('click', '.template-link', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo(`templates/${$(e.target).data('id')}`);
});

$(document).on('matgen-navigation', () => {
  //console.log('MATGEN NAVIGATION:', e);
  //MatgenGlobal.navigating = e;
  //$('#sidebar').remove();
});

/*
$(document).on('matgen-loading-stop', e => {
  console.error('MATGEN LOADING STOP:', e);
});
*/

$(document).on('input', '.pwcheck', e => {
  const pw = $(e.target).val();
  let valid = true;
  if (pw.length < 8) {
    valid = false;
  }
  if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(pw)) {
    valid = false;
  }

  if (!valid) e.target.setCustomValidity('Passwords must meet requirements');
  else e.target.setCustomValidity('');
});

$(document).on('input', '[data-equal-id]', e => {
  const to_confirm = $(e.target);
  const to_equal = $(`#${to_confirm.data('equalId')}`);

  if (to_confirm.val() != to_equal.val())
    e.target.setCustomValidity('Passwords must match');
  else e.target.setCustomValidity('');
});

$(document).on('change', '#inputPreviewType', () => {
  pfile = false;
  if ($('#inputPreviewType').val() === 'LINK') {
    $('#inputPreviewLink')
      .attr('required', true)
      .closest('.row')
      .show();
  } else {
    $('#inputPreviewLink')
      .removeAttr('required')
      .closest('.row')
      .hide();
  }
  if ($('#inputPreviewType').val() === 'IMAGE') {
    $(`
        <div class="row align-items-baseline">
          <label for="inputPreviewUpload" class="col-sm-4 col-form-label">Preview Image</label>
          <div class="col-sm-8">
            <input  onkeydown="event.preventDefault()" type="text" id="inputPreviewUpload" name="inputPreviewUpload" class="form-control top" placeholder="Preview Image" value="${
              document.getElementById('file-input') &&
              document.getElementById('file-input').files[0]
                ? document.getElementById('file-input').files[0].name
                : ''
            }" required>
          </div>
        </div>
      `).insertAfter($($('#inputPreviewType').closest('.row')));
    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('file-input')) {
        pfile = document.getElementById('file-input').files[0];
        $('#inputPreviewUpload').val(
          document.getElementById('file-input').files[0].name
        );
        document
          .querySelector('body')
          .removeChild(document.getElementById('file-input'));
      }
    });
  } else {
    $('#inputPreviewUpload')
      .closest('.row')
      .remove();
  }
});

$(document).on('click', '#inputTemplateFileUpload', () => {
  MatgenGlobal.UI.upload(() => {
    if (document.getElementById('template-file')) {
      tfile = document.getElementById('template-file').files[0];
      $('#inputTemplateFileUpload').val(
        document.getElementById('template-file').files[0].name
      );
      document
        .querySelector('body')
        .removeChild(document.getElementById('template-file'));
    }
  }, 'template-file');
});

$(document).on('click', '#inputTemplateThumbnailUpload', () => {
  MatgenGlobal.UI.upload(() => {
    if (document.getElementById('template-thumbnail')) {
      ttfile = document.getElementById('template-thumbnail').files[0];
      $('#inputTemplateThumbnailUpload').val(
        document.getElementById('template-thumbnail').files[0].name
      );
      document
        .querySelector('body')
        .removeChild(document.getElementById('template-thumbnail'));
    }
  }, 'template-thumbnail');
});

$(document).on('change', '#inputTemplateType', () => {
  tfile = false;
  if (['PDF', 'IMAGE'].includes($('#inputTemplateType').val())) {
    $('#inputWidth').attr('required', true);
    $('#inputHeight').attr('required', true);
    $('#inputWidth')
      .closest('.row')
      .show();
    $('#inputHeight')
      .closest('.row')
      .show();
    $('#inputPreviewType')
      .removeAttr('required')
      .closest('.row')
      .hide();
  } else {
    $('#inputWidth').removeAttr('required');
    $('#inputHeight').removeAttr('required');
    $('#inputWidth')
      .closest('.row')
      .hide();
    $('#inputHeight')
      .closest('.row')
      .hide();
  }
  if ($('#inputTemplateType').val() === 'PDF') {
    $('#inputWidth').val(2550);
    $('#inputHeight').val(3300);
  }
  if ($('#inputTemplateType').val() === 'IMAGE') {
    $('#inputWidth').val(1200);
    $('#inputHeight').val(1200);
  }
  if ($('#inputTemplateType').val() === 'FILE') {
    $('#inputPreviewType')
      .attr('required', true)
      .closest('.row')
      .show();

    $(`
            <div class="row align-items-baseline">
              <label for="inputTemplateFileUpload" class="col-sm-4 col-form-label">Template File</label>
              <div class="col-sm-8">
                <input  onkeydown="event.preventDefault()" type="text" id="inputTemplateFileUpload" name="inputTemplateFileUpload" class="form-control top" placeholder="Template File" value="${
                  document.getElementById('template-file') &&
                  document.getElementById('template-file').files[0]
                    ? document.getElementById('template-file').files[0].name
                    : ''
                }" required>
              </div>
            </div>
          `).insertAfter($($('#inputTemplateType').closest('.row')));

    $(`
          <div class="row align-items-baseline">
            <label for="inputTemplateThumbnailUpload" class="col-sm-4 col-form-label">Template Thumbnail</label>
            <div class="col-sm-8">
              <input  onkeydown="event.preventDefault()" type="text" id="inputTemplateThumbnailUpload" name="inputTemplatethumbnailUpload" class="form-control top" placeholder="Template Thumbnail" value="${
                document.getElementById('template-thumbnail') &&
                document.getElementById('template-thumbnail').files[0]
                  ? document.getElementById('template-thumbnail').files[0].name
                  : ''
              }" required>
            </div>
          </div>
        `).insertAfter($($('#inputTemplateFileUpload').closest('.row')));

    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('template-file')) {
        tfile = document.getElementById('template-file').files[0];
        $('#inputTemplateFileUpload').val(
          document.getElementById('template-file').files[0].name
        );
        document
          .querySelector('body')
          .removeChild(document.getElementById('template-file'));
      }
    }, 'template-file');
  } else {
    $('#inputTemplateFileUpload')
      .closest('.row')
      .remove();

    $('#inputTemplateThumbnailUpload')
      .closest('.row')
      .remove();
  }
});

/*
$(document).on('submit', '#forgot-password-form', async e => {
  e.preventDefault();

  MatgenGlobal.UI.loading('Sending confirmation code...');

  const response = await MatgenGlobal.AuthUser.forgotPassword(
    $('#inputEmail').val()
  );
  MatgenGlobal.UI.stopLoading();
  if (response === true) {
    MatgenGlobal.Router.goTo('forgot-password-confirm', () => {
      $('#forgot-password-confirm-form .form-success')
        .text(
          'Confirmation sent. Please check your email (allow a few minutes for delivery).'
        )
        .show();
    });
  } else {
    $('#forgot-password-form .form-error')
      .text(response.message)
      .show();
  }
});
*/

$(document).on('submit', '#forgot-password-confirm-form', async e => {
  e.preventDefault();

  MatgenGlobal.UI.loading('Resetting password...');

  const response = await MatgenGlobal.AuthUser.forgotPasswordSubmit(
    $('#inputEmail').val(),
    $('#inputConfirmationCode').val(),
    $('#inputPassword').val()
  );
  MatgenGlobal.UI.stopLoading();
  if (response === true) {
    MatgenGlobal.Router.goTo('/unauth');
  } else {
    $('#new-password-form .form-error')
      .text(response.message)
      .show();
  }
});

$(document).on('click', '#forgot-password', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/forgot-password');
});

$(document).on('click', '#cancel', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/unauth');
});

$(document).on('click', '#have-code', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/forgot-password-confirm');
});

//$(document).off('click', '#template-form-submit');
$(document).on('click', '#template-form-submit', () => {
  $('#template-form').submit();
});

//$(document).off('submit', '#template-form');
$(document).on('submit', '#template-form', async e => {
  e.preventDefault();
  if ($('#template-form')[0].checkValidity()) {
    const id = $('#inputTemplateID').val();
    const name = $('#inputTemplateName').val();
    const subtitle = $('#inputTemplateSubtitle').val();
    const uploads = $('#inputTemplateUploads').is(':checked') ? 1 : 0;
    const type = $('#inputTemplateType').val();
    const preview_type = $('#inputPreviewType').val();
    const preview_link = $('#inputPreviewLink').val();
    //const template_file = $('#inputTemplateFileUpload').val();
    //const preview_file = $('#inputPreviewUpload').val();
    const tenant_id = $('#inputTenantID').val();
    const width = $('#inputWidth').val();
    const height = $('#inputHeight').val();
    const fonts = $('#inputFonts').val();
    const instructions = $('#inputInstructions').val();
    let associated_file = null;
    let associated_file_link_text = null;

    let file_ext, preview_image_ext, thumbnail_image_ext;

    if (
      $('#associated-file-link-text').length > 0 &&
      $('#associated-file-uploader').length > 0
    ) {
      associated_file = $('#associated-file-uploader')[0].files[0].name;
      const ext = associated_file.split('.').pop();
      associated_file_link_text = $('#associated-file-link-text').val();
      associated_file = associated_file.replace(`.${ext}`, '');
      associated_file = `${associated_file}-${id}.${ext}`;

      try {
        const response = await MatgenGlobal.Amplify.Storage.put(
          `tenant/${$('#inputTenantID').val()}/templates/${associated_file}`,
          $('#associated-file-uploader')[0].files[0],
          {
            level: 'public',
            customPrefix: { public: '' },
            contentType: 'application/pdf',
            progressCallback(e) {
              MatgenGlobal.UI.loaderProgress(e);
            },
          }
        );
        console.log(response);
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    if (tfile) {
      file_ext = tfile.name.substr(
        tfile.name.lastIndexOf('.'),
        tfile.name.length
      );
    }
    if (pfile) {
      preview_image_ext = pfile.name.substr(
        pfile.name.lastIndexOf('.'),
        pfile.name.length
      );
    }

    if (ttfile) {
      thumbnail_image_ext = ttfile.name.substr(
        ttfile.name.lastIndexOf('.'),
        ttfile.name.length
      );
    }

    const payload = Object.entries({
      id,
      name,
      type,
      preview_type,
      preview_link,
      file_ext,
      preview_image_ext,
      thumbnail_image_ext,
      tenant_id,
      width,
      height,
      fonts,
      instructions,
      subtitle,
      uploads,
      associated_file,
      associated_file_link_text,
    }).reduce(
      (a, [k, v]) =>
        v === '' || typeof v === 'undefined' ? a : ((a[k] = v), a),
      {}
    );

    if (payload.fonts) {
      payload.fonts = JSON.parse(payload.fonts);
    }
    let newTemplate = false;
    if (!payload.id) {
      payload.id = UUID();
      newTemplate = true;
    }

    $('#template-form-modal').modal('hide');

    MatgenGlobal.UI.loading('Saving template data...');
    const template_response = await MatgenGlobal.Data.saveTemplate(
      payload,
      !newTemplate
    );

    if (template_response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem saving the template data.'
      );
      return false;
    }

    if (type === 'IMAGE' || type === 'PDF') {
      if (newTemplate) {
        console.log(template_response);
        MatgenGlobal.UI.loading('Saving page data...');

        const pageId = UUID();
        const page_response = await MatgenGlobal.Data.savePage({
          id: pageId,
          template_id: payload.id,
          number: 0,
        });

        if (page_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the page data.'
          );
          return false;
        }

        console.log(page_response);
        MatgenGlobal.UI.loading('Saving page JSON file...');

        MatgenGlobal.editor = await new MatgenEditor({
          id: payload.id,
          pageId: pageId,
          fonts: payload.fonts ? payload.fonts : null,
          containerId: 'matgen-edit-canvas',
          templateId: payload.id,
          width: payload.width,
          height: payload.height,
        });

        MatgenGlobal.editor.width = payload.width;
        MatgenGlobal.editor.height = payload.height;

        const editDiv = $(
          `<div id="matgen-edit-canvas" class="matgen-hidden-canvas"/>`
        );
        $('body').append(editDiv);

        await MatgenGlobal.editor.init('#matgen-edit-canvas');
        //eventsInit(MatgenGlobal.editor);
        const pageObj = await MatgenGlobal.UI.getPageObject();
        const page_file_response = await MatgenGlobal.Data.savePageFile(
          pageId,
          pageObj,
          payload.tenant_id
        );

        if (page_file_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the page JSON file.'
          );
          return false;
        }
        if (MatgenGlobal.sidebar) {
          window.setTimeout(() => {
            MatgenGlobal.sidebar.markTemplateClean();
          }, 350);
        }
        console.log(page_file_response);
        $('#matgen-loader').attr(
          'message',
          'Saving template preview image file...'
        );

        const preview_response = await MatgenGlobal.UI.savePagePreview(
          false,
          payload.tenant_id
        );

        if (preview_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the template preview image file.'
          );
          return false;
        }

        console.log(preview_response);
        editDiv.remove();
        delete MatgenGlobal.editor;
        MatgenGlobal.Router.goTo(`templates/${payload.id}`);
      } else {
        const row = $('#data-table')
          .DataTable()
          .row($(`td:contains(${payload.id})`).closest('tr'))
          .data();
        payload.tenant_name = row.tenant_name;
        $('#data-table')
          .DataTable()
          .row($(`td:contains(${payload.id})`).closest('tr'))
          .data(payload);
      }
    } else if (type === 'FILE') {
      MatgenGlobal.UI.loading('Saving template file...');
      try {
        const file_response = await MatgenGlobal.Data.saveTemplateFile(
          `${payload.id}${payload.file_ext}`,
          tenant_id,
          tfile
        );
        console.log(file_response);
      } catch (e) {
        console.error(e);
      }
      if (pfile) {
        MatgenGlobal.UI.loading('Saving preview image...');
        try {
          const file_response = await MatgenGlobal.Data.saveTemplateFile(
            `${payload.id}${payload.preview_image_ext}`,
            tenant_id,
            pfile
          );
          console.log(file_response);
        } catch (e) {
          console.error(e);
        }
      }
    }
    tfile = false;
    pfile = false;
    MatgenGlobal.UI.stopLoading();
    if (type === 'FILE') {
      MatgenGlobal.Router.goTo(`tags/${payload.id}`);
    }
  } else {
    $('#template-form')[0].reportValidity();
    window.setTimeout(() => {
      if (
        $('#inputPreviewUpload').length > 0 &&
        !$('#inputPreviewUpload').val()
      ) {
        MatgenGlobal.UI.upload(() => {
          if (document.getElementById('file-input')) {
            pfile = document.getElementById('file-input').files[0];
            $('#inputPreviewUpload').val(
              document.getElementById('file-input').files[0].name
            );
            document
              .querySelector('body')
              .removeChild(document.getElementById('file-input'));
          }
        });
      }
      if (
        $('#inputTemplateFileUpload').length > 0 &&
        !$('#inputTemplateFileUpload').val()
      ) {
        MatgenGlobal.UI.upload(() => {
          if (document.getElementById('template-file')) {
            tfile = document.getElementById('template-file').files[0];
            $('#inputTemplateFileUpload').val(
              document.getElementById('template-file').files[0].name
            );
            document
              .querySelector('body')
              .removeChild(document.getElementById('template-file'));
          }
        }, 'template-file');
      }
    }, 750);
  }
});

MatgenGlobal.Options = {
  env: API_ENV === 'production' ? 'prod' : 'dev',
  tenant_env: TENANT_ENV ? TENANT_ENV : 'm4c',
  containerId: 'matgen-scale-container-default',
  //editorTargetSelector: '#matgen-controller',
  scalingTargetId: 'matgen-scale-container-default-scale',
  sidebarTargetSelector: '#matgen-sidebar',
  rootPage: 'index.html',
};

MatgenGlobal.DEBUG_LOADER = API_ENV === 'production' ? false : true;
MatgenGlobal.DEBUG_LOADER = true;

MatgenGlobal.Amplify = {
  Auth,
  Storage,
  API,
};
MatgenGlobal.AmplifyConfig = MatgenConfig;
MatgenGlobal.SidebarId = 'sidebar';
Amplify.configure(MatgenGlobal.AmplifyConfig);

console.log('API_ENV:', API_ENV, 'TENANT_ENV:', TENANT_ENV);
console.log('AmplifyConfig', MatgenGlobal.AmplifyConfig);

$(document).on('matgen-table-action', e => {
  console.log('matgen-table-action:', e.detail);
  let data;
  switch (e.detail.action) {
    default:
      break;
    case 'template-mark-tested':
      data = $(MatgenGlobal.Tables.TemplateTable.tableSelector)
        .DataTable()
        .row($(e.detail.row))
        .data();
      console.log(data.tested, data.id);
      if (parseInt(data.tested) === 1) {
        console.log('Mark untested');
      } else {
        console.log('Mark tested');
      }
      break;
    case 'new-tenant':
      MatgenUIFunctions.saveForm('tenant', 'Tenant');
      break;
    case 'new-questionnaire':
      //MatgenUIFunctions.saveForm('questionnaire', 'Questionnaire');
      MatgenGlobal.Router.goTo(`/tenant-questionnaire/${e.detail.id}`);
      break;
    case 'new-section':
      MatgenUIFunctions.saveForm('section', 'Section');
      break;
    case 'new-question':
      MatgenUIFunctions.saveForm('question', 'Question');
      break;
    case 'new-answer':
      MatgenUIFunctions.saveForm('answer', 'Answer');
      break;
  }
});

$(document).on('questionnaire-form-action', e => {
  console.log('questionnaire-form-action:', e.detail);

  switch (e.detail.action) {
    default:
      break;
    case 'submit-save-questionnaire':
      console.log('Questionnaire saved!');
      // Submit all data
      MatgenUIFunctions.submitQuestionnaireForm();
      break;
    case 'add-question-section-1':
      // MatgenUIFunctions.saveForm('question', 'Question');
      MatgenUIFunctions.addQuestion(1);
      console.log('Add Question');
      break;
    case 'edit-question-section-1':
      MatgenUIFunctions.addQuestion(1, e.detail.id);
      console.log('Add Question');
      break;
    case 'add-question-section-2':
      // MatgenUIFunctions.saveForm('question', 'Question');
      MatgenUIFunctions.addQuestion(2);
      console.log('Add Question');
      break;
    case 'edit-question-section-2':
      MatgenUIFunctions.addQuestion(2, e.detail.id);
      console.log('Add Question');
      break;
    case 'add-question-section-3':
      // MatgenUIFunctions.saveForm('question', 'Question');
      MatgenUIFunctions.addQuestion(3);
      console.log('Add Question');
      break;
    case 'edit-question-section-3':
      MatgenUIFunctions.addQuestion(3, e.detail.id);
      console.log('Add Question');
      break;
    case 'add-question-section-4':
      // MatgenUIFunctions.saveForm('question', 'Question');
      MatgenUIFunctions.addQuestion(4);
      console.log('Add Question');
      break;
    case 'edit-question-section-4':
      MatgenUIFunctions.addQuestion(4, e.detail.id);
      console.log('Add Question');
      break;
    case 'add-answer':
      MatgenUIFunctions.addAnswer(e.detail.id);
      console.log(e.detail.id);
      console.log('Add Answer');
      break;
    case 'edit-answer':
      MatgenUIFunctions.addAnswer(null, e.detail.id);
      console.log('Add Answer');
      break;
  }
});

MatgenGlobal.CustomTemplateActions = item => {
  const tested = parseInt(item.tested);
  return [
    {
      action: 'mark-tested',
      title: tested === 1 ? 'Tested' : 'Untested',
      icon: tested === 1 ? 'fas fa-vial' : 'far fa-vial',
      color: tested === 1 ? 'primary' : 'warning',
    },
  ];
};

MatgenGlobal.LoginPage = '/login';

MatgenGlobal.init(MatgenGlobal.Options);

$('#page-loader')
  .hide()
  .remove();

$(document).ready(() => {
  if (MatgenGlobal.Options.tenant_env === 'nia') {
    $('#intranet-logo').hide();
    $('#intranet-logo-footer').hide();
  } else {
    $('#intranet-logo').show();
    $('#intranet-logo-footer').show();
  }
});

$(document).on('matgen-ready', async () => {
  /*if (MatgenGlobal.AuthUser.user) {
    console.log(await MatgenGlobal.AuthUser.getSessionToken());
    console.log(MatgenGlobal.AuthUser.user);
    const credentials = await MatgenGlobal.Amplify.Auth.currentUserCredentials();
    console.log('identityId', credentials.identityId, credentials);
  }*/
  //MatgenGlobal.SuppressSidebarActions = true;
  //const t = await MatgenGlobal.Data.request('/templates');
  //console.log(t);
  //MatgenGlobal.Suppress508 = true;

  MatgenGlobal.UI.stopLoading();
  //console.log('MATGEN READY EVENT:', MatgenGlobal);
  MatgenGlobal.Router.init(MatgenGlobal.Options.router);

  //$('#matgen-controller').empty();
  if (MatgenGlobal.AuthUser.user) {
    if (
      window.location.pathname === '/unauth' ||
      window.location.hash === '#/unauth' ||
      (window.location.pathname === '/' && !window.location.hash) ||
      window.location.hash === '#'
    ) {
      if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
        MatgenGlobal.Router.goTo('templates');
      } else {
        MatgenGlobal.Router.goTo('materials');
      }
    }
    //await loadMenus();
  } else {
    MatgenGlobal.Router.goTo('unauth');
  }
});
